const HeiLogo = () => (
    <svg
        width="164"
        height="27"
        viewBox="0 0 164 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M102.181 20.2208C102.181 22.0888 100.666 23.603 98.7975 23.603H59.8895C58.0209 23.603 56.5062 22.0888 56.5062 20.2208V6.69202C56.5062 4.82408 58.0209 3.30981 59.8895 3.30981L98.7975 3.30981C100.666 3.30981 102.181 4.82408 102.181 6.69202V8.38312H87.9709C87.9709 8.14536 87.9709 7.91918 87.9709 7.70629C87.9709 6.58553 87.062 5.67736 85.9409 5.67736H72.746C71.6249 5.67736 70.716 6.58592 70.716 7.70668V19.2062C70.716 20.3269 71.6249 21.2355 72.746 21.2355H85.9409C87.062 21.2355 87.9709 20.3269 87.9709 19.2062V14.8093H79.3435V12.4418H102.181V20.2208Z"
            fill="var(--white)"
        />
        <path
            d="M18.4452 23.6031L0.682861 3.30981H17.5994L27.7493 15.824L37.8992 3.30981H54.8158L37.0534 23.6031H18.4452Z"
            fill="var(--white)"
        />
        <rect
            x="108.991"
            width="54.3265"
            height="26.9128"
            rx="3.42643"
            fill="var(--white)"
        />
        <path
            d="M115.946 20.4564V6.78921H123.077V11.8203H125.731V6.78921H132.862V20.4564H125.731V15.0688H123.077V20.4564H115.946ZM135.033 20.4564V6.78921H146.917V10.0376H142.163V11.8996H146.521V15.0688H142.163V17.208H146.917V20.4564H135.033ZM149.012 20.4564V6.78921H156.143V20.4564H149.012Z"
            fill="var(--color-red-500)"
        />
    </svg>
);

export default HeiLogo;
